async function init() {
  await loadPolyfills();
  await loadSlot();
}

export async function loadPolyfills() {
  const polyfills: Promise<any>[] = [];

  if (!('fetch' in window)) {
    // @ts-ignore type definitions not working correctly
    polyfills.push(import('whatwg-fetch'));
  }

  if (!('registerElement' in document)) {
    polyfills.push(import('@webcomponents/webcomponentsjs'));
  }

  await Promise.all(polyfills);
}

async function loadSlot() {
  const slotElement = (await import('./slot-element')).default;
  window.customElements.define('content-slot', slotElement);
}

void init();
